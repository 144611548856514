import React, { useEffect, useState } from 'react';
import axios from 'axios';

function UserProfile({ token }) {
  const [userData, setUserData] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // Andmete muutmise vormi state
  const [editMode, setEditMode] = useState(false);
  const [fullName, setFullName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [institution, setInstitution] = useState('');

  // Parooli muutmise vormi state
  const [passwordMode, setPasswordMode] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Kasutaja andmete laadimine
  const fetchUserData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://helikon.technology:5000/user/me', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData(response.data.user);
      setResponseMessage('');
      // Täidame vormiväljad
      setFullName(response.data.user.fullName || '');
      setAddress(response.data.user.address || '');
      setPhone(response.data.user.phone || '');
      setInstitution(response.data.user.institution || '');
    } catch (error) {
      console.error('Kasutaja andmete laadimine ebaõnnestus:', error);
      setResponseMessage(
        error.response?.data?.message || 'Andmete laadimine ebaõnnestus.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, [token]);

  // Andmete salvestamine
  const handleSaveProfile = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        'https://helikon.technology:5000/user/me',
        {
          fullName,
          address,
          phone,
          institution,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setResponseMessage(response.data.message || 'Andmed uuendatud edukalt!');
      setEditMode(false);
      // Värskendame ekraanil olevaid andmeid
      fetchUserData();
    } catch (error) {
      console.error('Andmete uuendamine ebaõnnestus:', error);
      setResponseMessage(
        error.response?.data?.message || 'Andmete uuendamine ebaõnnestus.'
      );
    }
  };

  // Parooli salvestamine
  const handleSavePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setResponseMessage('Uus parool ja kinnitus ei ühti.');
      return;
    }
    try {
      const response = await axios.put(
        'https://helikon.technology:5000/user/me/password',
        {
          oldPassword,
          newPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setResponseMessage(response.data.message || 'Parool uuendatud edukalt!');
      setPasswordMode(false);
      // Tühjenda väljad
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Parooli uuendamine ebaõnnestus:', error);
      setResponseMessage(
        error.response?.data?.message || 'Parooli uuendamine ebaõnnestus.'
      );
    }
  };

  if (isLoading) {
    return (
      <div className="alert alert-warning text-center" style={{ marginTop: '20px' }}>
        Laadimine...
      </div>
    );
  }

  return (
    <div className="container mt-4">
      {/* Teade */}
      {responseMessage && (
        <div className="alert alert-info text-center">{responseMessage}</div>
      )}

      {userData && !editMode && !passwordMode && (
        <div className="card">
          <div className="card-body">
            <h2 className="card-title">Minu Profiil</h2>
            <p><strong>Kasutajanimi:</strong> {userData.username}</p>
            <p><strong>Email:</strong> {userData.email}</p>
            <p><strong>Telefon:</strong> {userData.phone || '-'}</p>
            <p><strong>Täisnimi:</strong> {userData.fullName || '-'}</p>
            <p><strong>Aadress:</strong> {userData.address || '-'}</p>
            <p><strong>Asutus:</strong> {userData.institution || '-'}</p>

            <button
              className="btn btn-primary me-2"
              onClick={() => setEditMode(true)}
            >
              Muuda andmeid
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => setPasswordMode(true)}
            >
              Muuda parooli
            </button>
          </div>
        </div>
      )}

      {/* Andmete muutmise vorm */}
      {editMode && (
        <div className="card">
          <div className="card-body">
            <h3>Muuda isiklikke andmeid</h3>
            <form onSubmit={handleSaveProfile}>
              <div className="mb-3">
                <label className="form-label">Täisnimi</label>
                <input
                  type="text"
                  className="form-control"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Aadress</label>
                <input
                  type="text"
                  className="form-control"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Telefon</label>
                <input
                  type="text"
                  className="form-control"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Asutus</label>
                <input
                  type="text"
                  className="form-control"
                  value={institution}
                  onChange={(e) => setInstitution(e.target.value)}
                />
              </div>

              <button type="submit" className="btn btn-primary me-2">
                Salvesta
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditMode(false)}
              >
                Tühista
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Parooli muutmise vorm */}
      {passwordMode && (
        <div className="card mt-3">
          <div className="card-body">
            <h3>Muuda parooli</h3>
            <form onSubmit={handleSavePassword}>
              <div className="mb-3">
                <label className="form-label">Vana parool</label>
                <input
                  type="password"
                  className="form-control"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Uus parool</label>
                <input
                  type="password"
                  className="form-control"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Uus parool uuesti</label>
                <input
                  type="password"
                  className="form-control"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>

              <button type="submit" className="btn btn-primary me-2">
                Salvesta parool
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setPasswordMode(false)}
              >
                Tühista
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserProfile;
