import React, { useEffect, useState } from 'react';
import { Link, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import axios from 'axios';
import AdminPanel from './AdminPanel';
import UploadHistory from './UploadHistory';
import UploadStudy from './UploadStudy';
import UserProfile from './UserProfile'
import StudyResponses from './StudyResponses';
import AdminDashboard from './AdminDashboard';
import UserRegistration from './Admin/UserRegistration';
import StudiesCount from './Functions/StudiesCount';

function Dashboard({ token, onLogout, userRole }) {
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);

  const navigate = useNavigate();

  // Lae kasutaja andmed (nt /validate-token)
  const fetchUserInfo = async () => {
    try {
      const response = await axios.get('https://helikon.technology:5000/validate-token', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserInfo(response.data.user);
    } catch (error) {
      console.error('Kasutaja andmete laadimine ebaõnnestus:', error);
      setResponseMessage('Sisselogimise seanss on aegunud. Palun logige uuesti sisse.');
      onLogout();
    } finally {
      setIsLoading(false);
    }
  };

  // Tervitusteate peitmine 10 sek järel
  useEffect(() => {
    const timer = setTimeout(() => setShowWelcomeMessage(false), 10000);
    return () => clearTimeout(timer);
  }, []);

  // Laeme info, kui token muutub
  useEffect(() => {
    fetchUserInfo();
  }, [token]);

  const handleLogout = () => {
    onLogout();
  };

  if (isLoading) {
    return (
      <div className="container mt-5">
        <div className="alert alert-warning text-center">Laadimine...</div>
      </div>
    );
  }

  return (
    <div className="container mt-4">
      {/* Navbar */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-center" style={{ marginTop: '20px' }}>
          <img
            src={`${process.env.PUBLIC_URL}/PildiTugi.png`}
            alt="PildiTugi logo"
            style={{ maxHeight: '230px' }}
          />
        </div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light mb-4">
          <ul className="navbar-nav">
            <li className="nav-item">
              {/* NB! Paneme 'uploads' teele, mis asub allpool Dashboardi <Routes> blockis */}
              <Link to="uploads" className="btn btn-link nav-link">
                Uuringud
              </Link>
            </li>
            <li className="nav-item">
              <Link to="upload-study" className="btn btn-link nav-link">
                Üleslaadimine
              </Link>
            </li>
            {userInfo?.role === 'admin' && (
              <li className="nav-item">
                <Link to="studies-count" className="btn btn-link nav-link">
                  Statistika
                </Link>
              </li>
            )}
            {userInfo?.role === 'admin' && (
              <li className="nav-item">
                <Link to="admin-dashboard" className="btn btn-link nav-link">
                  Kasutajad
                </Link>
              </li>
            )}
            {userInfo?.role === 'admin' && (
              <li className="nav-item">
                <Link to="add-response" className="btn btn-link nav-link">
                  Lisa vastus
                </Link>
              </li>
            )}
            {userInfo?.role === 'admin' && (
              <li className="nav-item">
                <Link to="user-registration" className="btn btn-link nav-link">
                  Kasutaja registreerimine
                </Link>
              </li>
            )}
          </ul>
          <button className="btn btn-danger ms-auto" onClick={handleLogout}>
            Logi välja
          </button>
        </nav>
      </div>

      {/* Tervitusteade (kui userInfo on käes ja 10 sekunid pole möödunud) */}
      {userInfo && showWelcomeMessage && (
        <div className="alert alert-success text-center">
          Tere tulemast, {userInfo.username}!
        </div>
      )}

      {/* Veateated */}
      {responseMessage && (
        <div className="alert alert-info text-center">{responseMessage}</div>
      )}

      {/* Dashboard-i sisesed Route-id */}
      <Routes>
        {/* Juur index - kui laetakse "/",
            suuname 'uploads' route'ile või võid otse <UploadHistory/> renderdada. */}
        <Route index element={<Navigate to="uploads" replace />} />

        <Route path="uploads" element={<UploadHistory token={token} />} />
        <Route path="upload-study" element={<UploadStudy token={token} />} />
        <Route path="study-responses" element={<StudyResponses token={token} />}/>
        <Route path="my-profile" element={<UserProfile token={token} />} />


        {/* Adminile mõeldud lehed */}
        {userInfo?.role === 'admin' && (
          <>
            <Route path="admin-dashboard" element={<AdminDashboard token={token} />} />
            <Route path="user-registration" element={<UserRegistration token={token} />} />
            <Route path="studies-count" element={<StudiesCount token={token} />} />
            <Route path="add-response" element={<AdminPanel token={token} />} />
          </>
        )}

        {/* Wildcard - 404 */}
        <Route
          path="*"
          element={
            <div style={{ textAlign: 'center', marginTop: '40px' }}>
              <h2>404 - Lehekülge ei leitud</h2>
            </div>
          }
        />
      </Routes>
    </div>
  );
}

export default Dashboard;
